var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"w-100 h-auto d-flex align-items-start justify-content-around"},[_c('div',{staticClass:"card",staticStyle:{"width":"70%"}},[_c('div',{staticClass:"card-header d-flex flex-column align-items-start justify-content-start"},[_c('h2',{staticClass:"mb-1 font-weight-bolder",staticStyle:{"width":"90%"}},[_vm._v(" "+_vm._s(this.taskDescription)+" ")]),_c('p',{staticClass:"card-subtitle",staticStyle:{"width":"90%"}},[_vm._v(" "+_vm._s(this.taskTitle)+" ")])]),_c('div',{staticClass:"card-body d-flex flex-column justify-content-around align-items-start mt-2"},[(
            _vm.control.question.question && _vm.control.question.question !== null
          )?_c('h6',{staticClass:"font-weight-bolder"},[_vm._v(" Assessment Question ")]):_vm._e(),(
            _vm.control.question.question && _vm.control.question.question !== null
          )?_c('div',{staticClass:"card",staticStyle:{"width":"90%"}},[_c('div',{staticClass:"card-body bg-light-secondary rounded py-2 mb-50"},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-start"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100",staticStyle:{"width":"40px"}},[_c('feather-icon',{staticClass:"mr-50 text-primary",attrs:{"icon":"HelpCircleIcon"}})],1),_c('h6',{staticClass:"font-weight-bold text-primary text-left mb-0"},[_vm._v(" "+_vm._s(_vm.control.question.question)+" ")])])])]):_vm._e(),(_vm.selectedGap.observation && _vm.selectedGap.observation !== null)?_c('h6',{staticClass:"font-weight-bolder"},[_vm._v(" Assessor Observation ")]):_vm._e(),(_vm.selectedGap.observation && _vm.selectedGap.observation !== null)?_c('div',{staticClass:"card",staticStyle:{"width":"90%"}},[_c('div',{staticClass:"card-body bg-light-secondary rounded py-2 mb-50"},[_c('p',{staticClass:"font-weight-bold card-subtitle text-left mb-0"},[_vm._v(" "+_vm._s(_vm.selectedGap.observation)+" ")])])]):_vm._e(),(
            _vm.selectedGap.recommendation && _vm.selectedGap.recommendation !== null
          )?_c('h6',{staticClass:"font-weight-bolder"},[_vm._v(" Assessor Recommendation ")]):_vm._e(),(
            _vm.selectedGap.recommendation && _vm.selectedGap.recommendation !== null
          )?_c('div',{staticClass:"card",staticStyle:{"width":"90%"}},[_c('div',{staticClass:"card-body bg-light-secondary rounded py-2"},[_c('p',{staticClass:"font-weight-bold card-subtitle text-left mb-0"},[_vm._v(" "+_vm._s(_vm.selectedGap.recommendation)+" ")])])]):_vm._e(),_c('validation-observer',{ref:"assess_form",staticClass:"w-100",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('b-form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick.apply(null, arguments)}}},[_c('div',{staticClass:"card w-100"},[_c('div',{staticClass:"card-header d-flex flex-column align-items-center justify-content-start"},[_c('h4',{staticClass:"font-weight-bolder"},[_vm._v(" Upload your requested evidences ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" All file types are supported & maximum size of each file should not exceed 10mb ")])]),_c('div',{staticClass:"card-body d-flex flex-column align-items-center justify-content-start"},[_c('validation-provider',{staticClass:"w-100",attrs:{"vid":"evidence_files","name":"Evidence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"upload-container rounded mb-2 d-flex flex-column align-items-center justify-content-center"},[_c('p',{staticClass:"text-center text-muted"},[_vm._v(" Click to browse or drag and drop your files ")]),_c('input',{staticClass:"input-file",attrs:{"type":"file","multiple":"","name":_vm.fileUpload.uploadFieldName,"accept":"*"},on:{"change":function($event){_vm.filesChange($event.target.name, $event.target.files);
                        _vm.fileCount = $event.target.files.length;}}}),_c('div',{staticClass:"d-flex flex-row flex-wrap align-items-center justify-content-center mt-1"},[(_vm.selectedGap.evidenceFiles)?_vm._l((_vm.selectedGap.evidenceFiles),function(file){return _c('b-badge',{key:file._id,staticClass:"mx-25 my-25",attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(file.name))])}):_vm._e()],2),_c('div',{staticClass:"w-100 d-flex flex-column align-items-start justify-content-center"},_vm._l((errors),function(error){return _c('small',{key:error,staticClass:"text-danger mb-25"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])]}}],null,true)}),_c('b-button',{attrs:{"disabled":invalid || _vm.selectedGap.evidenceFiles == null,"type":"submit","variant":"success"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_vm._v("Upload")],1)],1)])])]}}])})],1)]),_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-between w-25",staticStyle:{"position":"relative","height":"70vh"}},[_c('div',{staticClass:"card w-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-start"},[_c('h6',{staticClass:"font-weight-bolder mb-25"},[_vm._v("Framework:")]),(_vm.gap !== null && _vm.gap.standard && _vm.gap.standard !== null)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-secondary",modifiers:{"hover":true,"top":true,"v-secondary":true}}],staticClass:"ml-50 mb-25 text-truncate",staticStyle:{"max-width":"14vw"},attrs:{"variant":"primary","title":_vm.gap.standard.name}},[_vm._v(" "+_vm._s(_vm.gap.standard.name)+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-start"},[_c('h6',{staticClass:"font-weight-bolder mb-25"},[_vm._v("Assessor:")]),(
                _vm.gap !== null &&
                _vm.gap.assessed_user &&
                _vm.gap.assessed_user !== null
              )?_c('p',{staticClass:"text-secondary font-weight-bold ml-50 text-truncate mb-25",staticStyle:{"max-width":"14vw"}},[_vm._v(" "+_vm._s(_vm.gap.assessed_user.firstname)+" "+_vm._s(_vm.gap.assessed_user.lastname)+" ")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Deleted User")])],1),_c('div',{staticClass:"card bg-light-primary rounded mt-2"},[_c('div',{staticClass:"card-body py-2"},[_c('h6',{staticClass:"font-weight-bolder mb-50"},[_vm._v(" Control: "),(_vm.control !== null)?_c('b-badge',{staticClass:"ml-50",attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.control.control.control_no)+" ")]):_vm._e()],1),_c('div',{staticClass:"overflow-auto w-100 d-flex align-items-start justify-content-start pt-1",staticStyle:{"max-height":"16vh"}},[_c('p',{staticClass:"font-weight-bold text-primary text-left mb-0"},[_vm._v(" "+_vm._s(_vm.control.control.name)+" ")])])])])])]),(
          _vm.control.downloadable_evidences &&
          _vm.control.downloadable_evidences.length > 0
        )?_c('div',{staticClass:"card w-100 border border-primary",staticStyle:{"height":"80%","min-height":"450px"}},[_c('div',{staticClass:"card-header d-flex flex-column align-items-center justify-content-around"},[_c('h4',{staticClass:"font-weight-bolder"},[_vm._v("Uploaded Evidences")]),_c('div',{staticClass:"bg-light-secondary rounded py-50 px-50"},[_c('p',{staticClass:"mb-0 font-weight-bolder"},[_vm._v(" Total: "),_c('b-badge',{staticClass:"ml-25",attrs:{"pill":"","variant":"light-info"}},[_vm._v(_vm._s(_vm.control.downloadable_evidences.length))])],1)])]),_c('div',{staticClass:"card-body bg-light-secondary rounded py-2"},[_c('vue-perfect-scrollbar',{staticStyle:{"height":"100%","width":"100%"},attrs:{"settings":{ maxScrollbarLength: 60 }}},[_vm._l((_vm.control.downloadable_evidences),function(evidence){return [_c('div',{key:evidence._id,staticClass:"card"},[_c('div',{staticClass:"float-right pt-1 pr-1"},[(
                      evidence.uploaded_user &&
                      evidence.uploaded_user.user_id == _vm.UserIdStore
                    )?_c('feather-icon',{staticClass:"float-right",staticStyle:{"cursor":"pointer","color":"red"},attrs:{"icon":"Trash2Icon","variant":"danger"},on:{"click":function($event){return _vm.openDeleteEvidenceConfirm(evidence.file_id)}}}):_vm._e()],1),_c('div',{staticClass:"card-header d-flex align-items-center justify-content-center"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:evidence.file_id,staticClass:"d-flex flex-direction-column align-items-center justify-content-between my-75 cursor-pointer",staticStyle:{"position":"relative"},attrs:{"variant":"light-primary","title":'Download: ' + evidence.original_file_name},on:{"click":function($event){return _vm.handleDownloadButtonClick(
                        evidence.original_file_name,
                        evidence.file_id
                      )}}},[_c('div',{staticClass:"h-100 d-flex align-items-center justify-content-center",staticStyle:{"width":"27px"}},[(!_vm.downloadingList.includes(evidence.file_id))?_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"DownloadIcon","size":"27"}}):_c('b-spinner',{staticClass:"ml-0 mr-75",attrs:{"variant":"primary","small":""}})],1),_c('p',{staticClass:"truncate text-left mb-0"},[_vm._v(" "+_vm._s(evidence.original_file_name)+" ")])])],1),_c('div',{staticClass:"card-body d-flex flex-column align-items-start justify-content-start"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('p',{staticClass:"font-weight-bolder"},[_vm._v(" Uploader: "),(
                          evidence.uploaded_user &&
                          evidence.uploaded_user != null
                        )?_c('span',{staticClass:"text-primary pl-50"},[_vm._v(" "+_vm._s(evidence.uploaded_user.firstname)+" "+_vm._s(evidence.uploaded_user.lastname)+" ")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Deleted User")])],1)]),_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('p',{staticClass:"font-weight-bolder"},[_vm._v(" Uploaded At: "),_c('span',{staticClass:"text-muted pl-50"},[_vm._v(" "+_vm._s(_vm._f("moment")(evidence.uploaded_at))+" ")])])])])])]})],2)],1)]):_vm._e()])]),_c('b-modal',{ref:"modal-danger",attrs:{"id":"modal-danger","ok-only":"","ok-variant":"danger","modal-class":"modal-danger","centered":"","title":"Delete Evidence!","size":"lg"},on:{"ok":function($event){return _vm.deleteEvidence()}}},[_c('b-card-text',[_vm._v(" Are You Sure to Delete the evidence ? ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }