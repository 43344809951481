export default {
  methods: {

    getGapAssessmentResponseDetails(assessmentId, responseId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${assessmentId}/responses/${responseId}`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },


    getGapAssessmentDetails(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${id}`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    
    getGapAssessmentControlFamilyResponses(ga_id, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${ga_id}/responses`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },


    getSharedGapAssessments(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/shared`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    getGapAssessmentDashboard(assessmentId,widgetName){
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${assessmentId}/reports/dashboard`,
          params: widgetName,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    postGenerate(gapid) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${gapid}/reports`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    getGenerate(gapid) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${gapid}/reports`,
          responseType: "blob",
          headers: { "content-type": "application/x-www-form-urlencoded" },
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    getGapAssessments(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getGapDetails(gapAssessmentId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${gapAssessmentId}`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getGapAssessmentControls(gapAssessmentId, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${gapAssessmentId}/controls`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getGapAssessmentResponses(gapAssessmentId, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${gapAssessmentId}/responses`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getGapAssessmentResponse(gapAssessmentId, gapAssessmentResponseId, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${gapAssessmentId}/responses/${gapAssessmentResponseId}`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    updateGapAssessment(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();

        if (obj["notes"] && obj.notes !== null) {
          bodyFormData.append("notes", obj.notes);
        }
        if (obj["title"] && obj.title !== null) {
          bodyFormData.append("title", obj.title);
        }
        if (obj["status"] && obj.status !== null) {
          bodyFormData.append("status", obj.status);
        }

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${obj.gapAssessmentId}`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    deleteGapAssessment(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${id}`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    mapToPrimary(id){
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${id}/prefill-data-from-primary-assessment`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      })
    },

    updateGapAssessmentResponses(obj) {
      console.log("Response OBJ", obj);
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append(
          "gap_assessment_response_id",
          obj.gapAssessmentResponseId
        );
        if (obj["recommendation"] && obj.recommendation !== null) {
          bodyFormData.append("recommendation", obj.recommendation);
        }
        if (
          obj["dismiss_is_updated_flag"] &&
          obj.dismiss_is_updated_flag !== null
        ) {
          bodyFormData.append(
            "dismiss_is_updated_flag",
            obj.dismiss_is_updated_flag
          );
        }
        if (
          (obj["response_value"] && obj.response_value !== null) ||
          obj.response_value == 0
        ) {
          console.log("ResponseValue", obj["response_value"]);
          bodyFormData.append("response_value", obj.response_value);
          if (obj.response_value == 100) {
            bodyFormData.append("response", 1);
          } else if (obj.response_value == 0) {
            bodyFormData.append("response", 2);
          } else if (obj.response_value == -1) {
            bodyFormData.append("response", 3);
          } else {
            bodyFormData.append("response", 4);
          }
        }
        // if(obj["response_value"] && obj.response_value==null){
        //   bodyFormData.append("response", 3);
        // }
        if (obj["observation"] && obj.observation !== null) {
          bodyFormData.append("observation", obj.observation);
        }

        if (
          (obj["isCompensatoryControlsUsed"] &&
            obj.isCompensatoryControlsUsed !== null) ||
          obj.isCompensatoryControlsUsed == false
        ) {
          bodyFormData.append(
            "is_compensatory_controls_used",
            obj.isCompensatoryControlsUsed ? 1 : 0
          );
        }

        if (
          (obj["compensatory_controls_note"] &&
            obj.compensatory_controls_note !== null) ||
          obj.compensatory_controls_note == false
        ) {
          bodyFormData.append(
            "compensatory_controls_note",
            obj.compensatory_controls_note
          );
        }

        if (obj["relatedTaskId"] && obj.relatedTaskId !== null) {
          bodyFormData.append("related_task_id", obj.relatedTaskId);
        }

        if (obj["evidenceFiles"] && obj.evidenceFiles !== null) {
          for (let i = 0; i < obj.evidenceFiles.length; i++) {
            bodyFormData.append(`evidence_files[${i}]`, obj.evidenceFiles[i]);
          }
        } else if (
          obj["evidenceResponsibleUser"] &&
          obj.evidenceResponsibleUser !== null
        ) {
          bodyFormData.append(
            "evidence_responsible_user",
            obj.evidenceResponsibleUser.value
          );

          if (obj.taskDescription !== null) {
            bodyFormData.append("task_description", obj.taskDescription);
          }
          if (obj.taskName !== null) {
            bodyFormData.append("task_title", obj.taskName);
          }
          if (
            obj.notifyTaskAssignee !== null &&
            obj.notifyTaskAssignee !== false
          ) {
            bodyFormData.append("should_notify_task_assignee", 1);
          }

          if (obj.dueDate !== null) {
            bodyFormData.append("task_due_date", obj.dueDate);
          }
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${obj.gapAssessmentId}/responses`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    gapEvidenceApproveOrReject(obj) {
      // var relatedid = []
      // obj.related_task_ids.map((item)=>{
      //   relatedid.push(item)
      // })
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append(
          "gap_assessment_response_id",
          obj.gapAssessmentResponseId
        );

        if (obj.approveEvidence) {
          bodyFormData.append("approve_evidence", obj.approveEvidence);
        } else if (obj.rejectEvidence) {
          bodyFormData.append("reject_evidence", obj.rejectEvidence);
        }
        bodyFormData.append("gapAssessmentId", obj.gapAssessmentId);
        bodyFormData.append(
          "task_approve_reject_comment",
          obj.task_approve_reject_comment
        );
        // bodyFormData.append(
        //   "related_task_ids",
        //   relatedid
        // );
        if (obj.related_task_ids !== null) {
          obj.related_task_ids.map((taskid, i) => {
            bodyFormData.append(`related_task_ids[${i}]`, taskid);
          });
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${obj.gapAssessmentId}/responses`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    gapAssessmentEvidenceBulkUpload(obj) {
      return new Promise((resolve, reject) => {
        const fileList = Object.values(obj.evidenceFiles);
        const bodyFormData = new FormData();
        if (obj.description !== null) {
          bodyFormData.append("description", obj.description);
        }
        if (obj.control_ids !== null) {
          obj.control_ids.map((controlid, i) => {
            bodyFormData.append(`control_ids[${i}]`, controlid);
          });
        }
        for (let i = 0; i < fileList.length; i++) {
          bodyFormData.append(`evidence_files[${i}]`, fileList[i]);
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${obj.gapAssessmentId}/evidence-bulk-upload`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getGapAssessmentEvidenceFiles(gapAssessmentId, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${gapAssessmentId}/evidence-bulk-upload-files`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    GapAssessmentDetailsdeleteEvidence(
      gapAssessmentId,
      gapResponseId,
      file_id
    ) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append(`uploaded_file_id`, file_id);
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${gapAssessmentId}/responses/${gapResponseId}/remove-evidence`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
    getTaskComments(taskId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/tasks/${taskId}/comments`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    storeStandardToGap(params) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("notes", params.notes);
        bodyFormData.append("title", params.title);
        if (params.subsetId && params.subsetId !== null) {
          bodyFormData.append("subset_id", params.subsetId);
        }
        bodyFormData.append("standard_id", params.standardId);
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    updateGapAssessmentDetails(obj) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append(
          "gap_assessment_control_id",
          obj.gapAssessmentControlId
        );
        bodyFormData.append("status", obj.status);
        bodyFormData.append("observation", obj.observation);
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/gap-assessments/${obj.gapAssessmentId}/controls`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
  },
};
