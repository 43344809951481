<template>
    <div>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="btn-icon rounded-circle mb-1" @click="goBack" size="sm">
            <feather-icon icon="ArrowLeftIcon" />
        </b-button>
    </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
export default {
    components: {
        BButton
    },
    data() {
        return {

        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>