<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="w-100 h-auto d-flex align-items-start justify-content-around">
      <div class="card" style="width: 70%">
        <div
          class="card-header d-flex flex-column align-items-start justify-content-start"
        >
          <h2 class="mb-1 font-weight-bolder" style="width: 90%">
            {{ this.taskDescription }}
          </h2>
          <p class="card-subtitle" style="width: 90%">
            {{ this.taskTitle }}
          </p>
        </div>
        <div
          class="card-body d-flex flex-column justify-content-around align-items-start mt-2"
        >
          <h6
            class="font-weight-bolder"
            v-if="
              control.question.question && control.question.question !== null
            "
          >
            Assessment Question
          </h6>
          <div
            class="card"
            style="width: 90%"
            v-if="
              control.question.question && control.question.question !== null
            "
          >
            <div class="card-body bg-light-secondary rounded py-2 mb-50">
              <div
                class="d-flex flex-row align-items-center justify-content-start"
              >
                <div
                  class="d-flex align-items-center justify-content-center h-100"
                  style="width: 40px"
                >
                  <feather-icon
                    icon="HelpCircleIcon"
                    class="mr-50 text-primary"
                  />
                </div>

                <h6 class="font-weight-bold text-primary text-left mb-0">
                  {{ control.question.question }}
                </h6>
              </div>
            </div>
          </div>
          <h6
            class="font-weight-bolder"
            v-if="selectedGap.observation && selectedGap.observation !== null"
          >
            Assessor Observation
          </h6>
          <div
            class="card"
            style="width: 90%"
            v-if="selectedGap.observation && selectedGap.observation !== null"
          >
            <div class="card-body bg-light-secondary rounded py-2 mb-50">
              <p class="font-weight-bold card-subtitle text-left mb-0">
                {{ selectedGap.observation }}
              </p>
            </div>
          </div>
          <h6
            class="font-weight-bolder"
            v-if="
              selectedGap.recommendation && selectedGap.recommendation !== null
            "
          >
            Assessor Recommendation
          </h6>
          <div
            class="card"
            style="width: 90%"
            v-if="
              selectedGap.recommendation && selectedGap.recommendation !== null
            "
          >
            <div class="card-body bg-light-secondary rounded py-2">
              <p class="font-weight-bold card-subtitle text-left mb-0">
                {{ selectedGap.recommendation }}
              </p>
            </div>
          </div>
          <validation-observer
            ref="assess_form"
            #default="{ invalid }"
            class="w-100"
          >
            <b-form @submit.prevent="handleSubmitClick" class="w-100">
              <div class="card w-100">
                <div
                  class="card-header d-flex flex-column align-items-center justify-content-start"
                >
                  <h4 class="font-weight-bolder">
                    Upload your requested evidences
                  </h4>
                  <p class="font-weight-bold">
                    All file types are supported & maximum size of each file
                    should not exceed 10mb
                  </p>
                </div>

                <div
                  class="card-body d-flex flex-column align-items-center justify-content-start"
                >
                  <validation-provider
                    vid="evidence_files"
                    #default="{ errors }"
                    name="Evidence"
                    class="w-100"
                  >
                    <div
                      class="upload-container rounded mb-2 d-flex flex-column align-items-center justify-content-center"
                    >
                      <p class="text-center text-muted">
                        Click to browse or drag and drop your files
                      </p>

                      <input
                        type="file"
                        multiple
                        :name="fileUpload.uploadFieldName"
                        @change="
                          filesChange($event.target.name, $event.target.files);
                          fileCount = $event.target.files.length;
                        "
                        accept="*"
                        class="input-file"
                      />
                      <div
                        class="d-flex flex-row flex-wrap align-items-center justify-content-center mt-1"
                      >
                        <template v-if="selectedGap.evidenceFiles">
                          <b-badge
                            :key="file._id"
                            v-for="file in selectedGap.evidenceFiles"
                            variant="light-primary"
                            class="mx-25 my-25"
                            >{{ file.name }}</b-badge
                          >
                        </template>
                      </div>
                      <div
                        class="w-100 d-flex flex-column align-items-start justify-content-center"
                      >
                        <small
                          :key="error"
                          v-for="error in errors"
                          class="text-danger mb-25"
                        >
                          {{ error }}
                        </small>
                      </div>
                    </div>
                  </validation-provider>
                  <b-button
                    :disabled="invalid || selectedGap.evidenceFiles == null"
                    type="submit"
                    variant="success"
                    ><feather-icon
                      icon="UploadIcon"
                      class="mr-50"
                    />Upload</b-button
                  >
                </div>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>

      <div
        class="d-flex flex-column align-items-center justify-content-between w-25"
        style="position: relative; height: 70vh"
      >
        <div class="card w-100">
          <div class="card-body">
            <div
              class="d-flex flex-row align-items-center justify-content-start"
            >
              <h6 class="font-weight-bolder mb-25">Framework:</h6>
              <b-badge
                style="max-width: 14vw"
                class="ml-50 mb-25 text-truncate"
                variant="primary"
                v-b-tooltip.hover.top.v-secondary
                :title="gap.standard.name"
                v-if="gap !== null && gap.standard && gap.standard !== null"
              >
                {{ gap.standard.name }}
              </b-badge>
            </div>

            <div
              class="d-flex flex-row align-items-center justify-content-start"
            >
              <h6 class="font-weight-bolder mb-25">Assessor:</h6>
              <p
                style="max-width: 14vw"
                class="text-secondary font-weight-bold ml-50 text-truncate mb-25"
                v-if="
                  gap !== null &&
                  gap.assessed_user &&
                  gap.assessed_user !== null
                "
              >
                {{ gap.assessed_user.firstname }}
                {{ gap.assessed_user.lastname }}
              </p>
              <b-badge v-else variant="danger">Deleted User</b-badge>
            </div>

            <div class="card bg-light-primary rounded mt-2">
              <div class="card-body py-2">
                <h6 class="font-weight-bolder mb-50">
                  Control:
                  <b-badge
                    class="ml-50"
                    pill
                    variant="primary"
                    v-if="control !== null"
                  >
                    {{ control.control.control_no }}
                  </b-badge>
                </h6>
                <div
                  class="overflow-auto w-100 d-flex align-items-start justify-content-start pt-1"
                  style="max-height: 16vh"
                >
                  <p class="font-weight-bold text-primary text-left mb-0">
                    {{ control.control.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card w-100 border border-primary"
          style="height: 80%; min-height: 450px"
          v-if="
            control.downloadable_evidences &&
            control.downloadable_evidences.length > 0
          "
        >
          <div
            class="card-header d-flex flex-column align-items-center justify-content-around"
          >
            <h4 class="font-weight-bolder">Uploaded Evidences</h4>
            <div class="bg-light-secondary rounded py-50 px-50">
              <p class="mb-0 font-weight-bolder">
                Total:
                <b-badge pill variant="light-info" class="ml-25">{{
                  control.downloadable_evidences.length
                }}</b-badge>
              </p>
            </div>
          </div>
          <div class="card-body bg-light-secondary rounded py-2">
            <vue-perfect-scrollbar
              :settings="{ maxScrollbarLength: 60 }"
              style="height: 100%; width: 100%"
            >
              <template v-for="evidence in control.downloadable_evidences">
                <div :key="evidence._id" class="card">
                  <div class="float-right pt-1 pr-1">
                    <feather-icon
                      icon="Trash2Icon"
                      class="float-right"
                      variant="danger"
                      @click="openDeleteEvidenceConfirm(evidence.file_id)"
                      style="cursor: pointer; color: red"
                      v-if="
                        evidence.uploaded_user &&
                        evidence.uploaded_user.user_id == UserIdStore
                      "
                    />
                  </div>

                  <div
                    class="card-header d-flex align-items-center justify-content-center"
                  >
                    <b-badge
                      :key="evidence.file_id"
                      variant="light-primary"
                      style="position: relative"
                      class="d-flex flex-direction-column align-items-center justify-content-between my-75 cursor-pointer"
                      v-b-tooltip.hover
                      :title="'Download: ' + evidence.original_file_name"
                      @click="
                        handleDownloadButtonClick(
                          evidence.original_file_name,
                          evidence.file_id
                        )
                      "
                    >
                      <div
                        style="width: 27px"
                        class="h-100 d-flex align-items-center justify-content-center"
                      >
                        <feather-icon
                          v-if="!downloadingList.includes(evidence.file_id)"
                          icon="DownloadIcon"
                          class="mr-75"
                          size="27"
                        />
                        <b-spinner
                          v-else
                          class="ml-0 mr-75"
                          variant="primary"
                          small
                        />
                      </div>

                      <p class="truncate text-left mb-0">
                        {{ evidence.original_file_name }}
                      </p>
                    </b-badge>
                  </div>
                  <div
                    class="card-body d-flex flex-column align-items-start justify-content-start"
                  >
                    <div
                      class="d-flex align-items-center justify-content-start"
                    >
                      <p class="font-weight-bolder">
                        Uploader:
                        <span
                          v-if="
                            evidence.uploaded_user &&
                            evidence.uploaded_user != null
                          "
                          class="text-primary pl-50"
                        >
                          {{ evidence.uploaded_user.firstname }}
                          {{ evidence.uploaded_user.lastname }}
                        </span>
                        <b-badge v-else variant="danger">Deleted User</b-badge>
                      </p>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-start"
                    >
                      <p class="font-weight-bolder">
                        Uploaded At:
                        <span class="text-muted pl-50">
                          {{ evidence.uploaded_at | moment }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </vue-perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-danger"
      ref="modal-danger"
      ok-only
      ok-variant="danger"
      @ok="deleteEvidence()"
      modal-class="modal-danger"
      centered
      title="Delete Evidence!"
      size="lg"
    >
      <b-card-text>
        Are You Sure to Delete the evidence ?
        <!-- {{ user_detail }} -->
      </b-card-text>
    </b-modal>
  </b-overlay>
</template>

<script>
import GapAssessmentMixins from "../../mixins/GapAssessmentMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import GapQuestionAssessForm from "./components/GapQuestionAssessForm.vue";
import BackButton from "@/components/BackButton.vue"
import {
  BOverlay,
  BButton,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormCheckbox,
  BBadge,
  BImg,
  VBTooltip,
  BSpinner,
  BTabs,
  BTab,
  BFormDatepicker,
  BModal,
  
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import UserMixins from "../../mixins/UserMixins";
import TaskMixins from "../../mixins/TaskMixins";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import UtilsMixins from "../../mixins/UtilsMixins";
import moment from "moment";

export default {
  components: {
    VuePerfectScrollbar,
    GapQuestionAssessForm,
    BOverlay,
    BBadge,
    BButton,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
    BImg,
    VBTooltip,
    BSpinner,
    BTabs,
    BTab,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    required,
    BModal,
    BackButton
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  directives: { "b-tooltip": VBTooltip },

  data() {
    return {
      fileUpload: {
        uploadFieldName: "evidences",
        currentStatus: null,
      },
      file: null,
      downloadingList: [],
      showOverlay: false,
      gapAssessmentId: null,
      gapAssessmentResponseId: null,
      controlFamilyId: null,
      taskTitle: null,
      taskDescription: null,
      taskId: null,
      gap: null,
      control: null,
      currentUser: null,

      selectedGap: {
        observation: null,
        response: null,
        evidenceFiles: null,
        evidenceResponsibleUser: null,
        gapAssessmentId: null,
        gapAssessmentResponseId: null,
        recommendation: null,
      },
      fileId: null,
      UserIdStore: null,
    };
  },
  mixins: [
    ResponseMixins,
    GapAssessmentMixins,
    UserMixins,
    TaskMixins,
    UtilsMixins,
  ],
  // watch:{
  //   $route(to, from){
  //   // Put your logic here...
  //   this.load();
  // }
  // },
  mounted() {
    this.load();
    this.UserIdStore = this.$store.state.app.user.user_id;
  },
  methods: {
    taskdetails() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + `/tasks/${this.$route.query.task_idx}`,
      };
      this.$http(options)
        .then((res) => {
          this.taskTitle = res.data.data.title;
          this.taskDescription = res.data.data.description;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load() {
      this.taskdetails();
      const query = this.$route.query;
      // this.taskTitle = query.task_title;
      // this.taskDescription = query.task_description;
      this.taskId = query.task_id;
      this.selectedGap.gapAssessmentId = query.gapAssessmentId;
      this.selectedGap.gapAssessmentResponseId = query.gapAssessmentResponseId;
      this.selectedGap.controlFamilyId = query.controlFamilyId;
      this.getGapControlsAndSetData(
        query.gapAssessmentId,
        query.gapAssessmentResponseId,
        {
          control_family_id: query.control_family_id,
        }
      );
      this.getGapAssessmentDetailsAndSetData(query.gapAssessmentId);
      // this.getCurrentUserDetailsAndSetData();
    },
    openDeleteEvidenceConfirm(file_id) {
      this.fileId = file_id;
      this.$refs["modal-danger"].show();
    },
    deleteEvidence(file_id) {
      const query = this.$route.query;
      this.GapAssessmentDetailsdeleteEvidence(
        query.gapAssessmentId,
        query.gapAssessmentResponseId,
        this.fileId
      )
        .then((res) => {
          this.$refs["modal-danger"].hide();
          this.handleResponse(res);
          this.load();
        })
        .catch((error) => {
          this.handleError(error);
          console.log(error);
        });
    },

    handleSubmitClick() {
      let obj = {
        gapAssessmentId: this.selectedGap.gapAssessmentId,
        gapAssessmentResponseId: this.selectedGap.gapAssessmentResponseId,
        relatedTaskId: this.taskId,
        evidenceFiles: this.selectedGap.evidenceFiles,
      };
      this.updateGapAssessmentResponsesAndSetData(obj);
    },

    handleDownloadButtonClick(fileName, id) {
      if (!this.downloadingList.includes(id)) {
        this.downloadingList.push(id);
        const options = {
          method: "GET",
          responseType: "blob",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/file-uploads/${id}/download`,
        };
        this.debounceFunction(() => {
          this.$http(options)
            .then((res) => {
              var blob = new Blob([res.data]);
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, fileName);
              } else {
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob, {
                  type: "text/plain",
                });
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }

              this.downloadingList = this.downloadingList.filter(
                (item_id) => item_id !== id
              );
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },

    updateGapAssessmentResponsesAndSetData(obj) {
      this.showOverlay = true;
      this.updateGapAssessmentResponses(obj)
        .then((res) => {
          this.updateGapAssessmentResponsesFromResponse(res);
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
          if (err.response) {
            if (err.response.status === 422) {
              // console.log(err.response.data.errors);
              let errors = err.response.data.errors;
              const evidenceErrorExist =
                Object.keys(errors).findIndex((key) =>
                  key.includes("evidence_files")
                ) === -1
                  ? false
                  : true;
              if (evidenceErrorExist) {
                errors["evidence_files"] = Object.keys(errors).map((key) => {
                  console.log(key);
                  if (key.includes("evidence_files")) {
                    return errors[key][0];
                  }
                });
                this.$refs.assess_form.setErrors(errors);
              } else {
                this.$refs.assess_form.setErrors(err.response.data.errors);
              }
            }
          }
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    filesChange(fieldName, fileList) {
      if (!fileList.length) return;
      this.selectedGap.evidenceFiles = fileList;
    },

    getGapAssessmentDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getGapDetails(id)
        .then((res) => {
          console.log("G.A Details:", res);
          const gapData = res.data.data;
          this.gap = gapData;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getGapControlsAndSetData(gapAssessmentId, gapAssessmentResponseId, params) {
      this.showOverlay = true;

      this.getGapAssessmentResponse(
        gapAssessmentId,
        gapAssessmentResponseId,
        params
      )
        .then((res) => {
          let newControl = res.data.data;
          console.log("G.A.Response:", res);
          if (newControl.response !== null) {
            if (newControl.question.always_applicable != "1") {
              newControl["options"] = [
                { value: 1, text: "Yes" },
                { value: 2, text: "No" },
                { value: 3, text: "Not Applicable" },
              ];
            } else {
              newControl["options"] = [
                { value: 1, text: "Yes" },
                { value: 2, text: "No" },
              ];
            }
          } else {
            if (newControl.question.always_applicable != "1") {
              newControl["options"] = [
                { value: null, text: "Select an Option" },
                { value: 1, text: "Yes" },
                { value: 2, text: "No" },
                { value: 3, text: "Not Applicable" },
              ];
            } else {
              newControl["options"] = [
                { value: null, text: "Select an Option" },
                { value: 1, text: "Yes" },
                { value: 2, text: "No" },
              ];
            }
          }
          this.control = newControl;
          console.log("Control:", newControl);
          this.selectedGap.observation = newControl.observation;
          this.selectedGap.response = newControl.response;
          this.selectedGap.evidence_responsible_user =
            newControl.evidence_responsible_user;
          this.selectedGap.recommendation = newControl.recommendation;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    updateGapAssessmentResponsesFromResponse(obj) {
      this.$router.push({ name: "tasks" });
    },

    updateGapAssessmentResponsesAndSetData(obj) {
      this.showOverlay = true;
      this.updateGapAssessmentResponses(obj)
        .then((res) => {
          this.handleResponse(res);
          this.$router.push({ name: "tasks" });
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
.upload-container {
  width: 100%;
  height: 240px;
  border: 2px dashed #4b4b4b;
  position: relative;
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }
}
</style>
