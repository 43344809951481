<template>
  <div>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card" v-if="updatedResponse !== null">
        <div
          class="card-header border-bottom d-flex"
          :class="{ 'justify-content-center': false }"
        >
          <div
            style="width: 80%"
            class="d-flex justify-content-start align-items-center"
            :class="{ 'justify-content-center': false }"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              style="height: 100%"
            >
              <feather-icon
                icon="HelpCircleIcon"
                class="mr-75 text-primary"
                size="18"
              />
            </div>
            <h4 class="mb-0">
              <span class="align-middle text-primary">{{
                updatedResponse.question.question
              }}</span>
            </h4>
          </div>

          <div class="d-flex justify-content-end align-items-center">
            <b-button @click="closeSidebar()" variant="danger"
              ><feather-icon icon="XIcon"
            /></b-button>
          </div>
        </div>
        <div class="card-body">
          <validation-observer ref="assess_form" #default="{ invalid }">
            <b-form class="mt-2" @submit.prevent="handleSubmitClick">
              <div
                class="w-100 d-flex align-items-center justify-content-center mb-1"
              >
                <p class="font-weight-bolder mb-0">
                  Complete the required Fields and upload your Evidence
                </p>
              </div>

              <div
                class="d-flex flex-row align-items-center justify-content-between w-100"
              >
                <b-form-group label="Response" class="w-100">
                  <template slot="label">
                    Response <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    vid="response_value"
                    #default="{ errors }"
                    name="Response"
                    :rules="{
                      required: true,
                    }"
                  >
                    <b-form-select
                      v-model="selectedGap.response_value"
                      :options="response.options"
                      :disabled="gap.user_resource_permissions.update != true"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div
                  v-if="selectedGap.response_value == 100"
                  style="width: 800px"
                  class="rounded border d-flex flex-row align-items-center justify-content-around px-1 ml-1 py-50 mt-1"
                >
                  <p class="mb-0 font-weight-bold mr-1">
                    Compliance By Compensatory Controls
                  </p>

                  <b-form-checkbox
                    v-model="selectedGap.isCompensatoryControlsUsed"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </div>
              </div>

              <b-form-group
                label="Compensatory Compliance Note"
                v-if="
                  selectedGap.response_value == 100 &&
                  selectedGap.isCompensatoryControlsUsed === true
                "
              >
                <validation-provider
                  vid="compensatory_compliance_note"
                  #default="{ errors }"
                  name="Compensatory Compliance Note"
                >
                  <b-form-textarea
                    id="compensatory_compliance_note"
                    v-model="selectedGap.compensatory_compliance_note"
                    :disabled="gap.user_resource_permissions.update != true"
                    rows="4"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Recommendation">
                <validation-provider
                  vid="recommendation"
                  #default="{ errors }"
                  name="Recommendation"
                >
                  <!-- <b-form-input
                    v-model="selectedGap.recommendation"
                    :disabled="
                      taskEvidenceSubmission ||
                      $store.state.app.user.user_id !== assessedUserId
                    "
                  ></b-form-input> -->
                  <b-form-textarea
                    id="observation"
                    v-model="selectedGap.recommendation"
                    :disabled="gap.user_resource_permissions.update != true"
                    rows="4"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Observation">
                <validation-provider
                  vid="observation"
                  #default="{ errors }"
                  name="observation"
                >
                  <b-form-textarea
                    id="observation"
                    v-model="selectedGap.observation"
                    :disabled="gap.user_resource_permissions.update != true"
                    rows="4"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Evidence">
                <validation-provider
                  vid="evidence_files"
                  #default="{ errors }"
                  name="Evidence"
                >
                  <div
                    style="min-height: 140px; border: 2px dotted #4b4b4b"
                    class="rounded w-100 d-flex flex-column justify-content-center align-items-center py-75"
                  >
                    <div
                      class="d-flex flex-column align-items-center justify-content-center"
                    >
                      <feather-icon
                        icon="UploadCloudIcon"
                        class="text-dark"
                        size="24"
                      />
                      <input
                        type="file"
                        multiple
                        :name="fileUpload.uploadFieldName"
                        @change="
                          filesChange($event.target.name, $event.target.files)
                        "
                        accept="*"
                        class="input-file"
                        :disabled="gap.user_resource_permissions.update != true"
                      />
                      <p class="drag-area-text">
                        Drag your file(s) here to Upload<br />
                        or click to browse
                      </p>
                    </div>

                    <div
                      class="d-flex flex-column align-items-center justify-content-center mt-1"
                    >
                      <template v-if="selectedGap.evidenceFiles">
                        <b-badge
                          :key="file._id"
                          v-for="file in selectedGap.evidenceFiles"
                          variant="light-primary"
                          class="mb-25"
                          >{{ file.name }}</b-badge
                        >
                      </template>
                    </div>
                  </div>

                  <div
                    class="w-100 d-flex flex-column align-items-start justify-content-center"
                  >
                    <small
                      :key="error"
                      v-for="error in errors"
                      class="text-danger mb-25"
                    >
                      {{ error }}
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>

              <template v-if="gap.user_resource_permissions.update == true">
                <h4 class="text-center mb-1 mt-2 font-weight-bolder">OR</h4>
                <div
                  class="d-flex flex-column align-items-start justify-content-start w-100"
                >
                  <div
                    class="w-100 d-flex flex-column align-items-center justify-content-center"
                  >
                    <p class="text-center font-weight-bolder mb-0">
                      Assign User For Evidence Submission
                    </p>
                    <b-badge
                      v-if="
                        updatedResponse.tasks &&
                        updatedResponse.tasks.length > 0
                      "
                      @click="scrollToTasks"
                      pill
                      variant="primary"
                      class="cursor-pointer"
                      >View Assigned Tasks</b-badge
                    >
                  </div>

                  <b-form-group label="Assignee User" class="w-100">
                    <validation-provider
                      #default="{ errors }"
                      name="User"
                      :rules="{
                        required: false,
                      }"
                    >
                      <b-form-select
                        v-model="selectedUser"
                        :options="userOptions"
                        @change="handleAssigneeSelected"
                        :disabled="gap.user_resource_permissions.update != true"
                      ></b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Task Due Date" class="w-100">
                    <validation-provider
                      #default="{ errors }"
                      name="Task Due Date"
                    >
                      <b-form-datepicker
                        id="due-date"
                        v-model="selectedGap.dueDate"
                        :disabled="false"
                        :state="errors.length > 0 ? false : null"
                        :min="minDueDate"
                      ></b-form-datepicker>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Task Name" class="w-100">
                    <validation-provider #default="{ errors }" name="Task Name">
                      <b-form-textarea
                        id="task_name"
                        v-model="selectedGap.taskName"
                        :disabled="false"
                        rows="4"
                        max-rows="6"
                        :state="errors.length > 0 ? false : null"
                      ></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="Task Description" class="w-100">
                    <validation-provider
                      #default="{ errors }"
                      name="Task Description"
                    >
                      <b-form-textarea
                        id="task_description"
                        v-model="selectedGap.taskDescription"
                        :disabled="false"
                        rows="4"
                        max-rows="6"
                        :state="errors.length > 0 ? false : null"
                      ></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Should notify Task Assignee"
                    >
                      <b-form-checkbox
                        v-model="selectedGap.notifyTaskAssignee"
                        :value="true"
                        class="custom-control-primary"
                        :disabled="false"
                      >
                        Send a notification to the user (assignee)?
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </template>

              <b-button
                type="submit"
                variant="primary"
                class="mr-1 mt-1"
                :disabled="invalid"
              >
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span class="align-middle">Submit</span>
              </b-button>

              <!----------Tabs-------------->
              <div
                style="min-height: 240px; position: relative"
                class="card border rounded mt-3 pt-2 px-0"
                ref="tabs"
              >
                <b-tabs content-class="mt-2" justified v-model="currentTab">
                  <b-tab>
                    <template #title>
                      <span>Submitted Evidences</span>
                      <b-badge
                        class="ml-25"
                        pill
                        variant="primary"
                        v-if="
                          updatedResponse.downloadable_evidences &&
                          updatedResponse.downloadable_evidences.length > 0
                        "
                        >{{
                          updatedResponse.downloadable_evidences.length
                        }}</b-badge
                      >
                    </template>
                    <div
                      class="card-body d-flex flex-column justify-content-start align-items-center"
                      style="max-height: 700px; position: relative; width: 100%"
                    >
                      <template
                        v-if="!updatedResponse['downloadable_evidences']"
                      >
                        <div
                          style="width: 80%; height: 80%"
                          class="bg-light-primary rounded py-2 d-flex flex-column justify-content-center align-items-center"
                        >
                          <p class="font-weight-bold">No Files Uploaded</p>
                          <feather-icon size="24" icon="UploadIcon" />
                        </div>
                      </template>

                      <template v-else>
                        <div
                          style="width: 100%"
                          class="table-wrapper-scroll-y my-custom-scrollbar"
                        >
                          <div
                            class="w-100 d-flex align-items-center justify-content-center mb-75"
                            v-if="
                              updatedResponse.evidence_status == 3 ||
                              updatedResponse.evidence_status == 4
                            "
                          >
                            <h5 class="mb-0 mr-25">
                              The uploaded evidences has been
                            </h5>
                            <b-badge
                              variant="success"
                              v-if="updatedResponse.evidence_status == 3"
                              class="mb-0"
                            >
                              <feather-icon
                                icon="CheckCircleIcon"
                                class="mr-25"
                                size="24"
                              />Approved
                            </b-badge>
                            <b-badge
                              variant="danger"
                              v-if="updatedResponse.evidence_status == 4"
                              class="mb-0"
                            >
                              <feather-icon
                                icon="XCircleIcon"
                                class="mr-25"
                                size="24"
                              />Rejected
                            </b-badge>
                          </div>
                          <table role="table" class="table">
                            <thead role="rowgroup">
                              <tr role="row">
                                <th
                                  role="columnheader"
                                  scope="col"
                                  class="header"
                                >
                                  File
                                </th>
                                <th
                                  role="columnheader"
                                  scope="col"
                                  class="header"
                                >
                                  Uploaded By
                                </th>
                                <th
                                  role="columnheader"
                                  scope="col"
                                  class="header"
                                >
                                  Uploaded At
                                </th>
                                <th
                                  role="columnheader"
                                  scope="col"
                                  class="header"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody role="rowgroup">
                              <tr
                                v-for="evidence in updatedResponse.downloadable_evidences"
                                :key="evidence.file_id"
                                role="row"
                              >
                                <td
                                  aria-colindex="1"
                                  role="cell"
                                  style="padding-right: 0"
                                >
                                  <div
                                    style="width: 250px"
                                    class="d-flex justify-content-start"
                                  >
                                    <b-badge
                                      :key="evidence.file_id"
                                      variant="light-primary"
                                      class="d-flex flex-direction-column align-items-center justify-content-between my-75 cursor-pointer"
                                      v-b-tooltip.hover
                                      :title="
                                        'Download: ' +
                                        evidence.original_file_name
                                      "
                                      @click="
                                        handleDownloadButtonClick(
                                          evidence.original_file_name,
                                          evidence.file_id
                                        )
                                      "
                                    >
                                      <div
                                        style="width: 27px"
                                        class="h-100 d-flex align-items-center justify-content-center"
                                      >
                                        <feather-icon
                                          v-if="
                                            !downloadingList.includes(
                                              evidence.file_id
                                            )
                                          "
                                          icon="DownloadIcon"
                                          class="mr-75"
                                          size="27"
                                        />
                                        <b-spinner
                                          v-else
                                          class="ml-0 mr-75"
                                          variant="primary"
                                          small
                                        />
                                      </div>

                                      <p class="truncate text-left mb-0">
                                        {{ evidence.original_file_name }}
                                      </p>
                                    </b-badge>
                                  </div>
                                </td>
                                <td aria-colindex="2" role="cell">
                                  <p
                                    class="mb-0 font-weight-bolder"
                                    v-if="
                                      evidence.uploaded_user &&
                                      evidence.uploaded_user != null
                                    "
                                  >
                                    {{ evidence.uploaded_user.firstname }}
                                    {{ evidence.uploaded_user.lastname }}
                                  </p>
                                  <b-badge v-else variant="danger"
                                    >Deleted User</b-badge
                                  >
                                </td>
                                <td aria-colindex="3" role="cell">
                                  {{ evidence.uploaded_at | moment }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!-- Approve or Reject The Uploaded Evidences -->
                          <div
                            v-if="
                              updatedResponse.evidence_status == 2 &&
                              gap.user_resource_permissions.update == true
                            "
                            class="w-100 d-flex flex-column align-items-center justify-content-start mt-2"
                          >
                            <div
                              class="w-100 d-flex align-items-center justify-content-center"
                            >
                              <p class="mb-75 text-center">
                                Choose any of the following actions to
                                <b>Approve</b> or <b>Reject</b> the evidences
                                uploaded by the assigned users.
                              </p>
                            </div>

                            <div
                              class="w-100 d-flex align-items-center justify-content-center"
                            >
                              <b-button
                                size="sm"
                                variant="success"
                                @click="
                                  handleEvidenceApproveClick(updatedResponse)
                                "
                                ><feather-icon
                                  icon="CheckCircleIcon"
                                  class="mr-50"
                                /><span class="align-middle"
                                  >Approve Evidences</span
                                ></b-button
                              >
                              <b-button
                                size="sm"
                                variant="danger"
                                class="ml-50"
                                @click="
                                  handleEvidenceRejectClick(updatedResponse)
                                "
                                ><feather-icon
                                  icon="XCircleIcon"
                                  class="mr-50"
                                /><span class="align-middle"
                                  >Reject</span
                                ></b-button
                              >
                            </div>
                          </div>
                          <!-- End -->
                        </div>
                      </template>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <span>Assigned Tasks</span>
                      <b-badge
                        class="ml-25"
                        pill
                        variant="primary"
                        v-if="
                          updatedResponse.tasks &&
                          updatedResponse.tasks.length > 0
                        "
                        >{{ updatedResponse.tasks.length }}</b-badge
                      >
                    </template>
                    <div
                      class="card-body d-flex flex-column justify-content-start align-items-center"
                      style="max-height: 700px; position: relative; width: 100%"
                    >
                      <div
                        style="width: 100%"
                        class="table-wrapper-scroll-y my-custom-scrollbar"
                      >
                        <table role="table" class="table">
                          <thead role="rowgroup">
                            <tr role="row">
                              <th
                                role="columnheader"
                                scope="col"
                                class="header"
                              >
                                Title
                              </th>
                              <th
                                role="columnheader"
                                scope="col"
                                class="header"
                              >
                                Description
                              </th>
                              <th
                                role="columnheader"
                                scope="col"
                                class="header"
                              >
                                Assignee
                              </th>
                              <th
                                role="columnheader"
                                scope="col"
                                class="header text-center"
                              >
                                Due Date
                              </th>
                              <th
                                role="columnheader"
                                scope="col"
                                class="header text-center"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody role="rowgroup">
                            <tr
                              v-for="task in updatedResponse.tasks"
                              :key="task._id"
                              role="row"
                            >
                              <td
                                aria-colindex="1"
                                role="cell"
                                style="padding-right: 0; width: 120px"
                              >
                                <div
                                  style="width: 120px"
                                  class="d-flex justify-content-start"
                                  v-b-tooltip.hover.top.v-secondary
                                  :title="task.title"
                                >
                                  <p
                                    class="mb-0 font-weight-bolder text-truncate"
                                  >
                                    {{ task.title }}
                                  </p>
                                </div>
                              </td>
                              <td
                                aria-colindex="1"
                                role="cell"
                                style="padding-right: 0; width: 120px"
                              >
                                <div
                                  style="width: 120px"
                                  class="d-flex justify-content-start"
                                  v-b-tooltip.hover.top.v-secondary
                                  :title="task.description"
                                  v-if="
                                    task.description &&
                                    task.description !== null
                                  "
                                >
                                  <p
                                    class="mb-0 font-weight-bold text-truncate"
                                  >
                                    {{ task.description }}
                                  </p>
                                </div>
                              </td>
                              <td
                                aria-colindex="2"
                                role="cell"
                                style="padding-right: 0; min-width: 100px"
                              >
                                <div
                                  style="width: 100px"
                                  class="d-flex justify-content-start"
                                  v-b-tooltip.hover.top.v-secondary
                                  :title="
                                    task.assignee_user.firstname +
                                    ' ' +
                                    task.assignee_user.lastname
                                  "
                                  v-if="
                                    task.assignee_user &&
                                    task.assignee_user !== null
                                  "
                                >
                                  <p
                                    class="mb-0 font-weight-bold text-truncate"
                                  >
                                    {{ task.assignee_user.firstname }}
                                    {{ task.assignee_user.lastname }}
                                  </p>
                                </div>
                                <b-badge v-else variant="danger"
                                  >Deleted User</b-badge
                                >
                              </td>
                              <td aria-colindex="3" role="cell">
                                <div class="d-flex justify-content-center">
                                  <p class="mb-0 font-weight-bold">
                                    {{ task.due_date | moment }}
                                  </p>
                                </div>
                              </td>
                              <td role="cell">
                                <div class="d-flex justify-content-center">
                                  <b-button
                                    @click.stop="ViewComments(task._id)"
                                    variant="flat-success"
                                    class="btn-icon"
                                    v-b-tooltip.hover.top.v-secondary
                                    title="View Comments"
                                  >
                                    <feather-icon icon="MessageCircleIcon" />
                                  </b-button>
                                  <b-button
                                    @click.stop="showConfirmDeleteBox(task._id)"
                                    variant="flat-danger"
                                    class="btn-icon"
                                    v-b-tooltip.hover.top.v-secondary
                                    title="Delete Task"
                                  >
                                    <feather-icon icon="TrashIcon" />
                                  </b-button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-overlay>
    <b-modal title="Add Comment" ref="reject-comment" hide-footer size="lg">
      <div>
        <label>Comment</label>
        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          v-model="taskrejectcomment"
          class="form-control mb-1"
        ></textarea>

        <label for="">Choose Tasks</label>
        <!-- <select
          name=""
          id=""
          class="form-control mb-1"
          v-modal="related_task_ids"
        >
          <option
            :value="task._id"
            v-for="task in updatedResponse.tasks"
            :key="task._id"
          >
            {{ task._id }}
          </option>
        </select> -->
        <table role="table" class="table">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col" class="header">Title</th>
              <th role="columnheader" scope="col" class="header">
                Description
              </th>
              <th role="columnheader" scope="col" class="header">Assignee</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="task in updatedResponse.tasks"
              :key="task._id"
              role="row"
            >
              <td
                aria-colindex="1"
                role="cell"
                style="padding-right: 0; width: 120px"
              >
                <div
                  style="width: 120px"
                  class="d-flex justify-content-start"
                  v-b-tooltip.hover.top.v-secondary
                  :title="task.title"
                >
                  <input
                    type="checkbox"
                    :value="task._id"
                    v-model="related_task_ids"
                    class="mr-50"
                  />
                  <p class="mb-0 font-weight-bolder text-truncate">
                    {{ task.title }}
                  </p>
                </div>
              </td>
              <td aria-colindex="1" role="cell">
                {{ task.description }}
              </td>

              <td
                aria-colindex="2"
                role="cell"
                style="padding-right: 0; width: 100px"
              >
                <div style="width: 100px" class="d-flex justify-content-start">
                  <p
                    v-b-tooltip.hover.top.v-secondary
                    :title="
                      task.assignee_user.firstname +
                      ' ' +
                      task.assignee_user.lastname
                    "
                    v-if="task.assignee_user && task.assignee_user !== null"
                    class="mb-0 font-weight-bold text-truncate"
                  >
                    {{ task.assignee_user.firstname }}
                    {{ task.assignee_user.lastname }}
                  </p>
                  <b-badge v-else variant="danger">Deleted User</b-badge>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <button
          class="btn btn-danger mr-50"
          @click="submitReject()"
          :disabled="taskrejectcomment == ''"
        >
          Reject
        </button>
        <button class="btn btn-secondary" @click="cancelreject()">
          Cancel
        </button>
      </div>
    </b-modal>
    <b-modal title="Add Comment" ref="approve-comment" hide-footer size="lg">
      <div>
        <label>Comment</label>
        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          v-model="taskapprovecomment"
          class="form-control mb-1"
        ></textarea>

        <label for="">Choose Tasks</label>
        <!-- <select
          name=""
          id=""
          class="form-control mb-1"
          v-modal="related_task_ids"
        >
          <option
            :value="task._id"
            v-for="task in updatedResponse.tasks"
            :key="task._id"
          >
            {{ task._id }}
          </option>
        </select> -->
        <table role="table" class="table">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col" class="header">Title</th>
              <th role="columnheader" scope="col" class="header">
                Description
              </th>
              <th role="columnheader" scope="col" class="header">Assignee</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="task in updatedResponse.tasks"
              :key="task._id"
              role="row"
            >
              <td
                aria-colindex="1"
                role="cell"
                style="padding-right: 0; width: 120px"
              >
                <div
                  style="width: 120px"
                  class="d-flex justify-content-start"
                  v-b-tooltip.hover.top.v-secondary
                  :title="task.title"
                >
                  <input
                    type="checkbox"
                    :value="task._id"
                    v-model="related_task_ids"
                    class="mr-50"
                  />
                  <p class="mb-0 font-weight-bolder text-truncate">
                    {{ task.title }}
                  </p>
                </div>
              </td>
              <td aria-colindex="1" role="cell">
                {{ task.description }}
              </td>

              <td
                aria-colindex="2"
                role="cell"
                style="padding-right: 0; width: 100px"
              >
                <div style="width: 100px" class="d-flex justify-content-start">
                  <p
                    v-b-tooltip.hover.top.v-secondary
                    :title="
                      task.assignee_user.firstname +
                      ' ' +
                      task.assignee_user.lastname
                    "
                    v-if="task.assignee_user && task.assignee_user !== null"
                    class="mb-0 font-weight-bold text-truncate"
                  >
                    {{ task.assignee_user.firstname }}
                    {{ task.assignee_user.lastname }}
                  </p>
                  <b-badge v-else variant="danger">Deleted User</b-badge>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <button
          class="btn btn-success mr-50"
          @click="submitApprove()"
          :disabled="taskapprovecomment == ''"
        >
          Approve
        </button>
        <button class="btn btn-secondary" @click="cancelApprove()">
          Cancel
        </button>
      </div>
    </b-modal>
    <b-modal title="Comments" size="lg" ref="view-comments" ok-only>
      <div>
        <div v-if="this.taskcomments.length">
          <div
            v-for="comment in this.taskcomments"
            :key="comment._id"
            class="p-1 border rounded mb-50"
          >
            <h5>
              {{ comment.message }}
            </h5>
            <p v-if="comment.addedBy && comment.addedBy != null">
              <span class="mr-50">{{ comment.addedBy.firstname }}</span
              ><span>{{ comment.addedBy.lastname }} </span>
            </p>
            <b-badge v-else variant="danger">Deleted User</b-badge>
            <p>
              {{ comment.created_at | moment }}
            </p>
          </div>
        </div>
        <div v-else>
          <div class="p-1">
            <p class="text-success">
              <feather-icon icon="MessageCircleIcon" /> Comments empty
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BButton,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BFormCheckbox,
  BCardText,
  BBadge,
  BImg,
  VBTooltip,
  BSpinner,
  BTabs,
  BTab,
  BFormDatepicker,
  BModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import UserMixins from "../../../mixins/UserMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import GapAssessmentMixins from "../../../mixins/GapAssessmentMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import moment from "moment";

export default {
  components: {
    BOverlay,
    BButton,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormCheckbox,
    required,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    BBadge,
    BImg,
    VBTooltip,
    BSpinner,
    BTabs,
    BTab,
    BFormDatepicker,
    BCardText,
    BModal,
  },

  directives: { "b-tooltip": VBTooltip },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  data() {
    return {
      showOverlay: false,
      currentTab: 0,
      file: null,
      notApplicable: false,
      userSuggestions: [],
      userSearchPhrase: null,
      selectedUser: null,
      selectedUserName: null,
      downloadingList: [],
      userOptions: [{ text: "--Select a User--", value: null }],
      updatedResponse: null,
      minDueDate: null,
      fileUpload: {
        uploadFieldName: "evidences",
        currentStatus: null,
      },
      taskrejectcomment: "",
      related_task_ids: [],
      rejectResponse: [],
      approveResponse: [],
      related_task_ids_approve: [],
      taskapprovecomment: "",
      taskcomments: [],
    };
  },

  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    response: {
      type: Object,
      required: true,
    },
    gapAssessmentId: {
      type: String,
      required: true,
    },
    updateGapAssessmentResponsesFromResponse: {
      type: Function,
      required: true,
    },
    assessedUserId: {
      type: String,
      required: true,
    },
    selectedGap: {
      type: Object,
      required: true,
    },
    standard: {
      type: Object,
      required: true,
    },
    gap: {
      type: Object,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    handleFamilyClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    relatedTaskId: {
      type: String,
      required: false,
      default: null,
    },
  },

  mixins: [
    UserMixins,
    ResponseMixins,
    GapAssessmentMixins,
    TaskMixins,
    UtilsMixins,
  ],

  mounted() {
    // console.log("related Task Id", this.relatedTaskId);
    this.load();
  },

  watch: {
    userSearchPhrase(value) {
      this.getUsersBySearchPhraseAndSetData(value);
    },
    selectedUser(value) {
      this.selectedGap.evidenceResponsibleUser = value;
    },
  },

  methods: {
    getTaskComment() {},
    load() {
      this.setMinDueDate();
      this.getUsersAndSetData();
      this.getGapAssessmentResponseAndSetData(
        this.$route.params.id,
        this.response._id
      );
    },

    ViewComments(taskid) {
      // this.taskcomments = comments.comments;
      this.getTaskComments(taskid)
        .then((res) => {
          this.taskcomments = res.data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$refs["view-comments"].show();
    },

    handleEvidenceApproveClick(response) {
      this.approveResponse = response;
      this.$refs["approve-comment"].show();
    },
    handleEvidenceRejectClick(response) {
      this.rejectResponse = response;
      this.$refs["reject-comment"].show();
    },
    cancelreject() {
      this.$refs["reject-comment"].hide();
    },
    cancelApprove() {
      this.$refs["approve-comment"].hide();
    },
    submitReject(response) {
      const obj = {
        gapAssessmentResponseId: this.rejectResponse._id,
        gapAssessmentId: this.rejectResponse.gap_assessment_id,
        rejectEvidence: 1,
        task_approve_reject_comment: this.taskrejectcomment,
        related_task_ids: this.related_task_ids,
      };
      // debugger
      this.handleEvidenceApproveOrReject(obj);
      this.$refs["reject-comment"].hide();
    },
    submitApprove() {
      const obj = {
        gapAssessmentResponseId: this.approveResponse._id,
        gapAssessmentId: this.approveResponse.gap_assessment_id,
        approveEvidence: 1,
        task_approve_reject_comment: this.taskapprovecomment,
        related_task_ids: this.related_task_ids,
      };
      this.handleEvidenceApproveOrReject(obj);
      this.$refs["approve-comment"].hide();
    },

    handleAssigneeSelected() {
      const taskName = `Requirement  ${this.updatedResponse.control.control_no} - Upload evidence for gap assessment - ${this.gap.title} : ${this.standard.name}`;
      this.selectedGap.taskName = taskName;
    },

    scrollToTasks() {
      this.$refs["tabs"].scrollIntoView({ behavior: "smooth" });
      this.currentTab = 1;
    },

    setMinDueDate() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDueDate = date;
    },

    showConfirmDeleteBox(taskId) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete the Task?", {
          title: "Confirm Delete Action",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.handleDeleteClick(taskId);
          }
        });
    },
    handleDeleteClick(id) {
      this.removeTask(id)
        .then((res) => {
          console.log("Tasks", this.updatedResponse.tasks);
          let newTasks = this.updatedResponse.tasks.filter(
            (task) => task._id !== id
          );
          this.updatedResponse.tasks = newTasks;
          this.handleResponse(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },

    filesChange(fieldName, fileList) {
      if (!fileList.length) return;
      this.selectedGap.evidenceFiles = fileList;
    },

    getGapAssessmentResponseAndSetData(
      gapAssessmentId,
      gapAssessmentResponseId
    ) {
      this.showOverlay = true;
      this.getGapAssessmentResponse(gapAssessmentId, gapAssessmentResponseId)
        .then((res) => {
          this.updatedResponse = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleEvidenceApproveOrReject(obj) {
      this.showOverlay = true;
      this.gapEvidenceApproveOrReject(obj)
        .then((res) => {
          this.load();
          this.handleResponse(res);
          this.handleFamilyClick();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleSubmitClick() {
      let obj = {
        response_value: this.selectedGap.response_value,
        observation: this.selectedGap.observation,
        isCompensatoryControlsUsed: this.selectedGap.isCompensatoryControlsUsed,
        compensatory_controls_note:
          this.selectedGap.compensatory_compliance_note,
        recommendation: this.selectedGap.recommendation,
        gapAssessmentId: this.gapAssessmentId,
        gapAssessmentResponseId: this.response._id,
        relatedTaskId: null,
        taskName: this.selectedGap.taskName,
        taskDescription: this.selectedGap.taskDescription,
        notifyTaskAssignee:
          this.notifyTaskAssignee === true
            ? null
            : this.selectedGap.notifyTaskAssignee,
        dueDate: this.selectedGap.dueDate,
      };
      if (this.selectedGap.evidenceFiles !== null) {
        obj["evidenceFiles"] = this.selectedGap.evidenceFiles;
      } else if (this.selectedGap.evidenceResponsibleUser !== null) {
        obj["evidenceResponsibleUser"] =
          this.selectedGap.evidenceResponsibleUser;
      }
      this.updateGapAssessmentResponsesAndSetData(obj);
    },

    updateGapAssessmentResponsesAndSetData(obj) {
      this.showOverlay = true;
      this.updateGapAssessmentResponses(obj)
        .then((res) => {
          this.updateGapAssessmentResponsesFromResponse(res);
          this.handleResponse(res);
          this.closeSidebar();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
          if (err.response) {
            if (err.response.status === 422) {
              // console.log(err.response.data.errors);
              let errors = err.response.data.errors;
              const evidenceErrorExist =
                Object.keys(errors).findIndex((key) =>
                  key.includes("evidence_files")
                ) === -1
                  ? false
                  : true;
              if (evidenceErrorExist) {
                errors["evidence_files"] = Object.keys(errors).map((key) => {
                  console.log(key);
                  if (key.includes("evidence_files")) {
                    return errors[key][0];
                  }
                });
                this.$refs.assess_form.setErrors(errors);
              } else {
                this.$refs.assess_form.setErrors(err.response.data.errors);
              }
            }
          }
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleDownloadButtonClick(fileName, id) {
      if (!this.downloadingList.includes(id)) {
        this.downloadingList.push(id);
        const options = {
          method: "GET",
          responseType: "blob",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/file-uploads/${id}/download`,
        };
        this.debounceFunction(() => {
          this.$http(options)
            .then((res) => {
              var blob = new Blob([res.data]);
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, fileName);
              } else {
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob, {
                  type: "text/plain",
                });
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }

              this.downloadingList = this.downloadingList.filter(
                (item_id) => item_id !== id
              );
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },

    getUsersAndSetData() {
      this.getUsers({ dont_paginate: true })
        .then((res) => {
          let newUsers = res.data.data;
          newUsers.map((user) => {
            this.userOptions.push({
              text: `${user.firstname} ${user.lastname}`,
              value: user.user_id,
            });
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getUsersBySearchPhraseAndSetData(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getUsers({ search: searchPhrase, limit: 5 })
            .then((res) => {
              this.userSuggestions = [];
              let users = res.data.data.data;
              this.userSuggestions.push({ name: "users", data: users });
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        }, 250);
      }
    },

    onUserSelected(user) {
      console.log(user);
      this.selectedUser = user.item;
      this.selectedGap.evidenceResponsibleUser = user.item.user_id;
      this.selectedUserName = `${user.item.firstname} ${user.item.lastname}`;
    },
    userGetSuggestionValue(suggestion) {
      return `${suggestion.item.firstname} ${suggestion.item.lastname}`;
    },

    // handleDownloadButtonClick() {
    //   const options = {
    //     method: "GET",
    //     url: `${process.env.VUE_APP_BASEURL}/assets/import/csv/sample-download`,
    //   };
    //   this.$http(options)
    //     .then((res) => {
    //       console.log(res);
    //       var blob = new Blob([res.data]);
    //       if (window.navigator.msSaveOrOpenBlob) {
    //         window.navigator.msSaveBlob(blob, "sample.csv");
    //       } else {
    //         var a = window.document.createElement("a");

    //         a.href = window.URL.createObjectURL(blob, {
    //           type: "text/plain",
    //         });
    //         a.download = "sample.csv";
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //       }
    //     })
    //     .catch((err) => console.log(err));
    // },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="css" scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-todo.scss";
.my-custom-scrollbar {
  position: relative;
  height: 100%;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
.header {
  position: sticky;
  top: 0;
  z-index: 99;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  min-height: 120px;
  position: absolute;
  cursor: pointer;
}
.drag-area-text {
  text-align: center;
  padding: 4px 0;
}
</style>
